<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-btn text large @click="$router.back()">
              <v-icon>mdi-arrow-left</v-icon>
              返回
            </v-btn>
          </v-col>
          <v-spacer/>
          <v-col class="d-flex justify-end">
            <v-btn large class="success" @click="saveOrUpdate">
              <v-icon>
                mdi-content-save
              </v-icon>
              保存
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                {{ this.$route.params ? '编辑模板' : '添加模板' }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field label="标题" v-model="formData.title"></v-text-field>
                    <!--<v-text-field label="使用的变量" v-model="formData.varContent" persistent-hint hint="变量名请勿包含'{{}}'除非你确认必须包含。例如：'名字' 对应正文中 '{{名字}}' / '{{名字}}' 对应正文中 '{{{{名字}}}}'" v-pre></v-text-field>-->
                    <rich-editor v-model="formData.content"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import template from "@/utils/template";
import router from "@/router";
import RichEditor from "@/components/rich-editor";

export default {
  name: "add-template",
  components: {RichEditor},
  data() {
    return {
      formData: {
        content: "",
        title: "",
        userId: this.$store.state.user.id,
        varContent: ""
      }
    }
  },
  methods: {
    saveOrUpdate() {
      if (!this.formData.userId) {
        const token = localStorage["access_token"];  //在请求头中获取token
        let strings = token.split("."); //截取token，获取载体
        const userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/"))))); //解析，需要吧‘_’,'-'进行转换否则会无法解析
        this.formData.userId = userinfo.id
      }
      if (this.$route.params.id !== undefined) {
        template.updateTemplate(this.$route.params.id, this.formData, res => {
          if (res.code === 200) {
            router.back()
          }
        })
      } else {
        template.addTemplate(this.formData, res => {
          if (res.code === 200) {
            router.back()
          }
        })
      }
    }
  },
  created() {
    if (this.$route.params) {
      this.formData = {
        content: this.$route.params.content,
        title: this.$route.params.title,
        varContent: this.$route.params.varContent
      }
    }
  }
}
</script>

<style scoped>

</style>
