var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, 30, 50, 100],
            itemsPerPageText: '每页行数',
            itemsPerPageAllText: '所有'
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.templateId))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.shortTitle))]),_c('td',[_vm._v(_vm._s(new Date(item.createTime).toLocaleString()))]),_c('td',[_vm._v(_vm._s(new Date(item.sendTime).toLocaleString()))]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getColor(item)}},[_vm._v(" "+_vm._s(item.status ? '成功' : item.sendTime === null ? '发送中' : '失败')+" ")])],1)])}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }