<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn large @click="$router.push('/templates/add')" class="info">
          添加模板
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [15, 30, 50, 100],
              itemsPerPageText: '每页行数',
              itemsPerPageAllText: '所有'
            }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>{{ new Date(item.createTime).toLocaleString() }}</td>
              <td>{{ item.updateTime ? new Date(item.updateTime).toLocaleString() : '' }}</td>
              <td>{{ item.userId }}</td>
              <td>
                <v-row>
                  <v-btn icon @click="edit(item)">
                    <v-icon color="info">mdi-pencil</v-icon>
                  </v-btn>
                  <DeleteBtn @confirm="del(item)" :icon="true"/>
                </v-row>

              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import template from "@/utils/template";
import DeleteBtn from "@/components/DeleteBtn";

export default {
  name: "template-list",
  components: {DeleteBtn},
  data() {
    return {
      pageCount: 0,
      loading: true,
      options: {},
      total: 0,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: '标题', value: 'title'},
        {text: '创建时间', value: 'createTime'},
        {text: '更新时间', value: 'updateTime'},
        {text: '创建者', value: 'userId'},
        {text: '', sortable: false}
      ],
      items: []
    }
  },
  computed: {},
  methods: {
    getTemplateList() {
      const {page, itemsPerPage} = this.options
      template.getTemplateList(page, itemsPerPage, res => {
        this.items = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    edit(item) {
      this.$router.push({
        name: 'Edit Templates',
        params: item
      })
    },
    del(item) {
      template.removeTemplate(item.id, () => {
        this.getTemplateList()
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.getTemplateList()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
