<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [15, 30, 50, 100],
              itemsPerPageText: '每页行数',
              itemsPerPageAllText: '所有'
            }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ item.id }}</td>
              <!--              <td>{{item.userId}}</td>-->
              <td>{{ item.templateId }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.shortTitle }}</td>
              <td>{{ new Date(item.createTime).toLocaleString() }}</td>
              <td>{{ new Date(item.sendTime).toLocaleString() }}</td>
              <td>
                <v-chip
                    :color="getColor(item)"
                >
                  {{ item.status ? '成功' : item.sendTime === null ? '发送中' : '失败' }}
                </v-chip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logs from "@/utils/logs";

export default {
  name: "logs-list",
  data() {
    return {
      pageCount: 0,
      loading: true,
      options: {},
      total: 0,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        // {text: '发送人', value: 'userId'},
        {text: '模板ID', value: 'templateId', sortable: false,},
        {text: '标题', value: 'title'},
        {text: '收件人', value: 'shortTitle'},
        {text: '创建时间', value: 'createTime'},
        {text: '发送时间', value: 'sendTime'},
        {text: '发送状态', value: 'status', sortable: false,},
      ],
      items: []
    }
  },
  computed: {},
  methods: {
    getLogList() {
      const {page, itemsPerPage} = this.options
      logs.getLogList(page, itemsPerPage, res => {
        this.items = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    getColor(item) {
      let status = item.status;
      let sendTime = item.sendTime;
      if (status) return 'green';
      else if (sendTime === null) return 'orange';
      else return 'red'
    },
  },
  watch: {
    options: {
      handler() {
        this.getLogList()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
