export default {
    // 测试
    // BASE_URL: 'http://127.0.0.1:8080',
    // SSO_URL: 'http://127.0.0.1:8082',

    // 生产环境
    BASE_URL: 'https://api.ozocean12.com',
    SSO_URL: 'https://sso.ozocean12.com',

    //个人相关
    USER_INFO: '/user/me',

    //登录相关
    LOGIN: '/oauth/token',
    REGISTER: '/oauth/register',
    CHECK_TOKEN: '/oauth/check_token',

    //模板相关
    GET_TEMPLATE: '/template/',
    GET_TEMPLATE_LIST: '/template/list',
    ADD_TEMPLATE: '/template/add',
    UPDATE_TEMPLATE: '/template/update',
    REMOVE_TEMPLATE: '/template/delete',

    //日志相关
    GET_LOGS: '/log/',
    GET_LOGS_LIST: '/log/list',

    //统计相关
    GET_STATISTICS: '/statistics/',
    GET_STATISTICS_LIST: '/statistics/list',
    // 点击邮件链接
    GET_STATISTICS_SHORTURL: '/statistics/shorturl',

    //邮件相关
    SEND_EMAIL: '/send/email',

    //配置相关
    GET_CONFIG: '/config/marker',
    UPDATE_CONFIG: '/config/update/marker',
}
