<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="4">
        <v-card v-if="login">
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center">
                <div class="text-h4">
                  登录
                </div>
              </v-col>
            </v-row>
            <v-text-field label="用户名" v-model="username" type="text"></v-text-field>
            <v-text-field label="密码" v-model="password" type="password"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn block @click="doLogin">登录</v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="switchLogin">注册</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <v-row>
              <v-col>
                <v-btn @click="switchLogin" text>
                  <v-icon>mdi-arrow-left</v-icon>
                  返回
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <div class="text-h4">
                  注册
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="用户名" v-model="username" type="text"></v-text-field>
                <v-text-field label="密码" v-model="password" type="password" :rules="rules"></v-text-field>
                <v-text-field label="再次输入密码" v-model="repassword" type="password" :rules="rules"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn block @click="doLogin">注册</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import login from "@/utils/login";

export default {
  name: "login-page",
  data() {
    return {
      login: true,
      username: '',
      password: '',
      repassword: '',
      rules: [
        value => !!value || '必填',
        value => (value || '').length >= 6 || '最少6个字符',
        value => this.password === value || '两次输入的密码不同',
      ],
    }
  },
  created() {

  },
  methods: {
    switchLogin() {
      this.login = !this.login
    },
    doLogin() {
      login.login({password: this.password, username: this.username}, (res) => {
        this.$store.state.tokens = res
        this.$store.state.logined = true
        this.$router.push("/templates")
      })
    },
    doRegister() {
      login.register()
    }
  }
}
</script>

<style scoped>

</style>
