import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";


function getStatisticsList(page, size, func) {
    axios.get(
        apiConfig.GET_STATISTICS_LIST + "?page=" + page + "&size=" + size
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

function getShortList(page, size, func) {
    axios.get(
        apiConfig.GET_STATISTICS_SHORTURL + "?page=" + page + "&size=" + size
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

export default {
    getStatisticsList,
    getShortList
}
