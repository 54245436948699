<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [15, 30, 50, 100],
              itemsPerPageText: '每页行数',
              itemsPerPageAllText: '所有'
            }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ item.id }}</td>
              <!--              <td>{{item.userId}}</td>-->
              <td>{{ item.userId }}</td>
              <td>{{ item.email }}</td>
                <td>
                   {{ item.type | getTypeName}}
                </td>
              <td>{{ item.createTime | formatUTC}}</td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statistics from "@/utils/statistics";

export default {
  name: "statistics-list",
  data() {
    return {
      pageCount: 0,
      loading: true,
      options: {},
      total: 0,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        // {text: '发送人', value: 'userId'},
        {text: '原用户ID', value: 'userId', sortable: false,},
        {text: '用户邮件', value: 'email'},
        {text: '邮件类型', value: 'type'},
        {text: '创建时间', value: 'createTime'},
      ],
      items: []
    }
  },
  filters: {
      getTypeName: function(type) {
          var str = '';
          switch (type) {
              case 'new_user': str = '新人'; break;
              case 'new_vip': str = '新VIP'; break;
              case 'opposite': str = '高评分异性'; break;
              case 'recommend': str = '匹配用户推荐'; break;
              case 'remind': str = '提醒'; break;
              case 'login_remind': str = '登陆提醒'; break;
              case 'guide_visitor': str = '访问通知'; break;
              case 'guide_msg': str = '有私信'; break;
              case 'attention': str = '关注者上线'; break;
              case 'approve': str = '审核认证通过'; break;
          }
          return str;
      },
      formatUTC: function(originVal) {
          const date = new Date(originVal)
          const y = date.getFullYear()
          const m = '0' + (date.getMonth() + 1)
          const d = '0' + date.getDate()
          const h = (date.getHours() + '').padStart(2, '0')
          const mm = (date.getMinutes() + '').padStart(2, '0')
          const ss = (date.getSeconds() + '').padStart(2, '0')
          // 2021-09-14 19:36:10
          return `${y}-${m.substring(m.length - 2, m.length)}-${d.substring(d.length - 2, d.length)} ${h}:${mm}:${ss}`
      }
  },
  methods: {
    getStatisticsList() {
      const {page, itemsPerPage} = this.options
      statistics.getStatisticsList(page, itemsPerPage, res => {
        this.items = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.getStatisticsList()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
