var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"info",attrs:{"large":""},on:{"click":function($event){return _vm.$router.push('/templates/add')}}},[_vm._v(" 添加模板 ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, 30, 50, 100],
            itemsPerPageText: '每页行数',
            itemsPerPageAllText: '所有'
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(new Date(item.createTime).toLocaleString()))]),_c('td',[_vm._v(_vm._s(item.updateTime ? new Date(item.updateTime).toLocaleString() : ''))]),_c('td',[_vm._v(_vm._s(item.userId))]),_c('td',[_c('v-row',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-pencil")])],1),_c('DeleteBtn',{attrs:{"icon":true},on:{"confirm":function($event){return _vm.del(item)}}})],1)],1)])}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }