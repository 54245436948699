import axios from 'axios'
import api from "./apiConfig";
import store from "@/store";
import snackBar from "@/components/SnackBar";

const instance = axios.create({
    baseURL: api.BASE_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(request => {
    if (localStorage.getItem('access_token') != null) {
        request.headers['Authorization'] = 'Bearer ' + localStorage['access_token']
    } else {
        this.$router.push("/login")
    }
    return request
})


function refreshToken() {
    const refreshUrl = api.SSO_URL + api.LOGIN + "?grant_type=refresh_token&scope=all&refresh_token=" + encodeURIComponent(localStorage.getItem('refresh_token'))
    const instance = axios.create({
        baseURL: refreshUrl,
        timeout: 300000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic Y2xpZW50OmZlRlEjcWZANGcjJCVINDZKNjc='
        }
    })
    return instance.post(
        refreshUrl
    ).then(result => {
        localStorage.setItem('refresh_token', result.data.refresh_token)
        return result.data.access_token
    }).catch(() => {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token')
        store.state.logined = false
        this.$router.push('/portal')
        snackBar.Launch("Credential expired, please login again!")
        return null
    })
}

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

instance.setToken = (token) => {
    instance.defaults.headers['Authorization'] = 'Bearer ' + token
    localStorage.setItem('access_token', token)
}

instance.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401 && localStorage.getItem('refresh_token') !== null) {
        const config = error.config
        if (!isRefreshing) {
            isRefreshing = true
            return refreshToken().then(token => {
                if (token == null) {
                    this.$router.push('/portal')
                    return Promise.reject(error)
                }
                instance.setToken(token)
                config.headers['Authorization'] = 'Bearer ' + token
                // 已经刷新了token，将所有队列中的请求进行重试
                requests.forEach(cb => cb(token))
                requests = []
                return instance(config)
            }).catch(res => {
                console.error('refreshtoken error =>', res)
            }).finally(() => {
                isRefreshing = false
            })
        } else {
            // 正在刷新token，将返回一个未执行resolve的promise
            return new Promise((resolve) => {
                // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                requests.push((token) => {
                    config.headers['Authorization'] = 'Bearer ' + token
                    resolve(instance(config))
                })
            })
        }
    }
    return Promise.reject(error)
})

export default {
    get: instance.get,
    post: instance.post,
}
