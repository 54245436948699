<template>
  <v-app-bar app color="blue" dark light>
    <v-toolbar color="blue" dark light flat>
      <v-toolbar-title>{{ $route.meta.page }}</v-toolbar-title>
    </v-toolbar>
    <v-btn icon v-if="$store.state.logined" @click="setting">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <v-btn icon v-if="$store.state.logined" @click="logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import router from "@/router";

export default {
  name: "AppBar",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  methods: {
    setting(){
      router.push("/me")
    },
    logout(){
      localStorage.clear()
      this.$store.state.logined = false
      this.$store.state.user = {}
      this.$store.state.tokens = {}
      router.push("/login")
    }
  }
}
</script>

<style scoped>

</style>
