import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";

function getUserInfo(){
    axios.get(
        apiConfig.SSO_URL+apiConfig.USER_INFO
    ).then(res => {
        store.state.user = res.data.data
    }).catch(err => {
        store.dispatch("lunchSnackBar", "获取用户信息失败：" + err);
    })
}

export default {
    getUserInfo
}