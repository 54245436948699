import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        SnackBar: {
            text: '',
            timeout: -1,
            snackbar: false
        },
        loading: false,
        NavItems: [
            {
                id: 1,
                title: "邮件模板",
                icon: "mdi-email",
                url: '/templates'
            },
            {
                id: 2,
                title: "个人信息",
                icon: "mdi-human-greeting",
                url: '/me'
            },
            {
                id: 3,
                title: "发送邮件",
                icon: "mdi-email-fast",
                url: '/send-email'
            },
            {
                id: 4,
                title: "邮件发送日志",
                icon: "mdi-history",
                url: '/logs'
            },
            {
                id: 5,
                title: "标识配置",
                icon: "mdi-pencil",
                url: '/configs'
            },
            {
                id: 6,
                title: "邮件打开统计",
                icon: "mdi-cog",
                url: '/statistics'
            },
            {
                id: 6,
                title: "邮件点击",
                icon: "mdi-history",
                url: '/shorturl'
            }
        ],
        logined: false,
        user: {},
        tokens: undefined
    },
    mutations: {
        LUNCH_SNACK_BAR(state, text, timeout = 3000) {
            state.SnackBar.text = text
            state.SnackBar.timeout = timeout
            state.SnackBar.snackbar = true
        },
        SET_LOADING(state, loading){
            state.loading = loading
        }
    },
    actions: {
        lunchSnackBar({commit}, text, timeout = 3000) {
            commit('LUNCH_SNACK_BAR', text, timeout);
        },
        setLoading({commit}, loading){
            commit('SET_LOADING', loading)
        }
    },
    modules: {
        getSnackBar: state => state.SnackBar,
        getBotTokens: state => state.botTokens,
        getLoading: state => state.loading,
        getNavItems: state => state.NavItems
    }
})
