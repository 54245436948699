import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";

function addTemplate(data, func) {
    axios.post(
        apiConfig.ADD_TEMPLATE,
        data
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "添加失败：" + err);
    })
}

function getTemplateList(page, size, func) {
    axios.get(
        apiConfig.GET_TEMPLATE_LIST + "?page=" + page + "&size=" + size
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

function getTemplate(id, func) {
    axios.get(
        apiConfig.GET_TEMPLATE + id
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

function updateTemplate(id, data, func) {
    axios.post(
        apiConfig.UPDATE_TEMPLATE + '?id=' + id,
        data
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "更新失败：" + err);
    })
}

function removeTemplate(id, func) {
    axios.post(
        apiConfig.REMOVE_TEMPLATE + "?id=" + id
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "删除失败：" + err);
    })
}

export default {
    addTemplate,
    getTemplateList,
    getTemplate,
    updateTemplate,
    removeTemplate
}
