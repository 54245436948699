<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [15, 30, 50, 100],
              itemsPerPageText: '每页行数',
              itemsPerPageAllText: '所有'
            }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ item.id }}</td>
              <td>{{ item.shortUrl }}</td>
              <td width="60%">
                  <div class="textover">
                      <a :href="item.oriUrl" target="_blank">{{ item.oriUrl }}</a>
                  </div>
              </td>
              <td>{{ item.clickNum}}</td>
              <td>{{ item.updateTime | formatUTC}}</td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statistics from "@/utils/statistics";

export default {
  name: "shorturl-list",
  data() {
    return {
      pageCount: 0,
      loading: true,
      options: {},
      total: 0,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        // {text: '发送人', value: 'userId'},
        {text: '短链接', value: 'shortUrl', sortable: false,},
        {text: '原URL链接', value: 'oriUrl'},
        {text: '点击次数', value: 'clickNum'},
        {text: '更新时间', value: 'updateTime'},
      ],
      items: []
    }
  },
  filters: {
      formatUTC: function(originVal) {
          const date = new Date(originVal)
          const y = date.getFullYear()
          const m = '0' + (date.getMonth() + 1)
          const d = '0' + date.getDate()
          const h = (date.getHours() + '').padStart(2, '0')
          const mm = (date.getMinutes() + '').padStart(2, '0')
          const ss = (date.getSeconds() + '').padStart(2, '0')
          // 2021-09-14 19:36:10
          return `${y}-${m.substring(m.length - 2, m.length)}-${d.substring(d.length - 2, d.length)} ${h}:${mm}:${ss}`
      }
  },
  methods: {
    getStatisticsList() {
      const {page, itemsPerPage} = this.options
      statistics.getShortList(page, itemsPerPage, res => {
        this.items = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.getStatisticsList()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.textover {
    max-width: 600px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>
