import apiConfig from "@/utils/apiConfig";
import axios from "axios";
import api from "@/utils/apiConfig";
import store from "@/store";
import router from "@/router";
import user from "@/utils/user";

const instance = axios.create({
    baseURL: api.SSO_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Basic Y2xpZW50OmZlRlEjcWZANGcjJCVINDZKNjc=",
    }
})

function check(func) {
    if (localStorage['access_token'] == null) {
        func(false)
        return;
    }
    instance.post(
        apiConfig.CHECK_TOKEN,
        {
            token: localStorage['access_token']
        }
    ).then(res => {
        store.state.logined = true
        func(res.data)
    }).catch(() => {
        store.state.logined = false
        router.push("/login")
        store.dispatch("lunchSnackBar", "登陆凭证失效，请重新登陆！");
    })
}

function refresh(func) {
    if (localStorage['refresh_token'] == null) {
        func(false)
        return;
    }
    instance.post(
        apiConfig.LOGIN,
        {
            refresh_token: localStorage['refresh_token'],
            grant_type: "refresh_token",
            access_token: localStorage['access_token'],
        }
    ).then(res => {
        store.state.logined = true
        localStorage['access_token'] = res.data.access_token
        localStorage["refresh_token"] = res.data.refresh_token
        func(res.data)
    }).catch(() => {
        store.state.logined = false
        router.push("/login")
        store.dispatch("lunchSnackBar", "登陆凭证失效，请重新登陆！");
    })
}

function login(data, func) {
    instance.post(
        apiConfig.LOGIN,
        {
            grant_type: "password",
            username: data.username,
            scope: "all",
            password: data.password
        }
    ).then(res => {
        if (res.data.access_token) {
            localStorage["access_token"] = res.data.access_token
            localStorage["refresh_token"] = res.data.refresh_token
            user.getUserInfo()
            func(res.data)
            store.dispatch("lunchSnackBar", "登录成功！");
            return;
        }
        store.dispatch("lunchSnackBar", "登陆失败：" + res.data.message);
    }).catch(err => {
        store.dispatch("lunchSnackBar", "登陆失败：" + err);
    })
}

function register(data, func) {
    instance.post(
        apiConfig.REGISTER,
        {
            username: data.username,
            password: data.password,
            gradeId: 2,
            validTime: "2023/12/25 00:00:00"
        }
    ).then(() => {
        login(data, func)
    }).catch(err => {
        store.dispatch("lunchSnackBar", "登陆失败：" + err);
    })
}

export default {
    login,
    register,
    check,
    refresh
}
