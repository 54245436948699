<template>
  <v-container>
    <v-row>
      <v-col>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
  name: 'rich-editor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: Editor,
      editorData: this.value,
      editorConfig: {
        mediaEmbed: {
          toolbar: []
        },
        image: {},
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: 'https://api.ozocean12.com/image/upload',

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            Authorization: 'Bearer ' + localStorage["access_token"]
          }
        }
      },
    };
  },
  model: {
    prop: "value", //绑定的值，通过父组件传递
    event: 'update'
  },
  methods: {},
  watch: {
    "value": function (newVal) {
      this.editorData = this.value
      this.$emit("update", newVal);
    },
    "editorData": function (newVal) {
      this.$emit("update", newVal);
    },
  }
}
</script>
<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
