<template>
  <v-container>
    <v-container v-if="!sendSuccess">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn class="success" @click="sendEmail">发送邮件</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="收件人" v-model="dataform.to"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select label="选择模板" :items="items" item-text="title" item-value="id"
                    v-model="selectedTemplate"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-col v-if="varmaps.length > 0" cols="12">
            <v-row>
              <div class="text-body-1">变量表</div>
            </v-row>
            <v-row v-for="(item, i) in varmaps" :key="i">
              <v-col>
                <v-text-field v-model="varmaps.at(i).key"></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex align-self-auto justify-center">
                <div class="text-h5"><v-icon x-large>mdi-arrow-right</v-icon></div>
              </v-col>
              <v-col>
                <v-text-field v-model="varmaps.at(i).value"></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex align-self-auto justify-center">
                <v-btn text icon @click="removeVarMapKeys(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-start">
            <v-btn @click="addVarMapKeys">
              <v-icon>mdi-plus</v-icon>
              添加变量
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="标题" v-model="dataform.subject"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <rich-editor v-model="dataform.content"/>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-img src="../assets/email.gif" max-width="400"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-h3">发送成功</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn class="success" x-large @click="sendSuccess = false">再发一封</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import template from "@/utils/template";
import email from "@/utils/email";
import store from "@/store";
import RichEditor from "@/components/rich-editor";

export default {
  name: "send-email",
  components: {RichEditor},
  data() {
    return {
      loading: true,
      items: [],
      total: 0,
      dataform: {
        moduleId: undefined,
        to: "",
        subject: "",
        varMap: {},
        content: "",
        replaceable: true
      },
      varmaps: [],
      selectedTemplate: undefined,
      sendSuccess: false
    }
  },
  methods: {
    getTemplateList(page, itemsPerPage) {
      template.getTemplateList(page, itemsPerPage, res => {
        this.items = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    addVarMapKeys() {
      this.varmaps.push(
          {
            key: "",
            value: ""
          }
      )
    },
    removeVarMapKeys(i) {
      this.varmaps.splice(i, 1)
    },
    sendEmail() {
      if (!this.validate()) {
        store.dispatch("lunchSnackBar", "请正确填写邮件信息！");
        return;
      }
      this.dataform.varMap = {}
      this.dataform.replaceable = this.varmaps.length > 0
      for (const item in this.varmaps) {
        this.dataform.varMap[this.varmaps.at(parseInt(item)).key] = this.varmaps.at(parseInt(item)).value
      }
      email.sendEmail(this.dataform, res => {
        if (res.code === 200) {
          this.sendSuccess = true
        }
      })
    },
    validate() {
      return this.dataform.subject.trim() !== "" && this.dataform.to.trim() !== "" && this.dataform.content.trim() !== ""
    },
  },
  created() {
    this.getTemplateList(1, 10000)
  },
  watch: {
    selectedTemplate(newTemp) {
      template.getTemplate(newTemp, res => {
        this.dataform.content = res.data.content
        this.dataform.subject = res.data.title
        this.varmaps = []
          if (res.data.varContent) {
              var vars = res.data.varContent.split(',')
              for (var each in vars) {
                  this.varmaps.push({key: vars[each], value: ""})
              }
          }
      })
    }
  }
}
</script>

<style scoped>

</style>
