import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";


function getLogList(page, size, func) {
    axios.get(
        apiConfig.GET_LOGS_LIST + "?page=" + page + "&size=" + size
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

function getLog(id, func) {
    axios.get(
        apiConfig.GET_LOGS + "/" + id
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

export default {
    getLogList,
    getLog,
}
