import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";
import axiosA from "axios";

function sendEmail(dataform, func) {
    axios.post(
        apiConfig.SEND_EMAIL,
        dataform
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "发送失败：" + err);
    })
}

function sendEmailByClickSend(data, func) {
    axiosA.post(
        "https://rest.clicksend.com/v3/email/send",
        {
            to: [{
                "email": data.to,
            }],
            from: {
                "email_address_id": "20675",
                "name": "小考拉"
            },
            subject: data.subject,
            body: data.content
        },
        {
            headers: {
                'Content-Type': "application/json",
                'Authorization': 'Basic bGl0dGxla2FvbGExQGdtYWlsLmNvbTpERjhEMjM4NS1FNDU5LUI1REMtRjY3OS1FNjAzQjIzRTBDQTA='
            }
        }
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "发送失败：" + err);
    })
}

export default {
    sendEmail,
    sendEmailByClickSend
}
