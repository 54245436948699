<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            个人信息
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field readonly outlined label="用户名" v-model="$store.state.user.username"></v-text-field>
                <v-text-field readonly outlined label="等级" v-model="$store.state.user.gradeId"></v-text-field>
                <v-text-field readonly outlined label="剩余API次数" v-model="$store.state.user.surplus"></v-text-field>
                <v-text-field readonly outlined label="余额" v-model="$store.state.user.balance"></v-text-field>
                <v-text-field readonly outlined label="加入时间" v-model="$store.state.user.createTime"></v-text-field>
                <v-text-field readonly outlined label="权限等级" v-model="$store.state.user.authorities"></v-text-field>
                <v-text-field readonly outlined label="个人描述" v-model="$store.state.user.remark"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import user from "@/utils/user";

export default {
  name: "personal-page",
  data(){
    return {
      user: {

      }
    }
  },
  created() {
    user.getUserInfo()
  }
}
</script>

<style scoped>

</style>