<template>
  <v-navigation-drawer app id="nav" v-model="$store.state.logined">
    <v-card>
      <v-card-title>
        <v-avatar class="ma-2">
          <v-img src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"/>
        </v-avatar>
        <div style="{font-style:oblique;}">
          邮件服务
        </div>
      </v-card-title>
    </v-card>
    <v-divider/>
    <v-list nav>
      <div v-for="item in $store.state.NavItems" :key="item.id" class="my-2">
        <v-list-item link :to="item.url">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "NavBar",
  components: {
  },
  data() {
    return {
    }
  },
  watch: {},
  mounted() {

  }
}
</script>

<style scoped>
#nav {
  overflow: hidden !important;
}
</style>
