import axios from "@/utils/axios";
import apiConfig from "@/utils/apiConfig";
import store from "@/store";

function getConfig(func) {
    axios.get(
        apiConfig.GET_CONFIG + "?id=templateTOid"
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "获取失败：" + err);
    })
}

function updateConfig(data, func) {
    axios.post(
        apiConfig.UPDATE_CONFIG + "?id=templateTOid",
        data
    ).then(res => {
        func(res.data)
    }).catch((err) => {
        store.dispatch("lunchSnackBar", "更新失败：" + err);
    })
}

export default {
    getConfig,
    updateConfig
}
