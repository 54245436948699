var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, 30, 50, 100],
            itemsPerPageText: '每页行数',
            itemsPerPageAllText: '所有'
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.shortUrl))]),_c('td',{attrs:{"width":"60%"}},[_c('div',{staticClass:"textover"},[_c('a',{attrs:{"href":item.oriUrl,"target":"_blank"}},[_vm._v(_vm._s(item.oriUrl))])])]),_c('td',[_vm._v(_vm._s(item.clickNum))]),_c('td',[_vm._v(_vm._s(_vm._f("formatUTC")(item.updateTime)))])])}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }