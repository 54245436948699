import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/pages/Login";
import AddTemplate from "@/pages/add-template";
import login from "@/utils/login";
import personalPage from "@/pages/personal-page";
import TemplateList from "@/pages/template-list";
import logsList from "@/pages/logs-list";
import sendEmail from "@/pages/send-email";
import configPage from "@/pages/config-page";
import statistics from "@/pages/statistics-list";
import shortUrlList from "@/pages/shorturl-list";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Templates',
        component: TemplateList,
        meta: {
            title: '模板列表',
            page: '模板列表',
            requiresAuth: true
        }
    },
    {
        path: '/templates',
        name: 'Templates',
        component: TemplateList,
        meta: {
            title: '模板列表',
            page: '模板列表',
            requiresAuth: true
        }
    },
    {
        path: '/templates/add',
        name: 'Add Templates',
        component: AddTemplate,
        meta: {
            title: '新增模板',
            page: '新增模板配置',
            requiresAuth: true
        }
    },
    {
        path: '/templates/edit',
        name: 'Edit Templates',
        component: AddTemplate,
        meta: {
            title: '编辑模板',
            page: '编辑模板配置',
            requiresAuth: true
        }
    },
    {
        path: '/me',
        name: 'Me',
        component: personalPage,
        meta: {
            title: '个人信息',
            page: '个人信息',
            requiresAuth: true
        }
    },
    {
        path: '/logs',
        name: 'Logs',
        component: logsList,
        meta: {
            title: '邮件发送日志',
            page: '邮件发送日志',
            requiresAuth: true
        }
    },
    {
        path: '/send-email',
        name: 'Send Email',
        component: sendEmail,
        meta: {
            title: '发送邮件',
            page: '发送邮件',
            requiresAuth: true
        }
    },
    {
        path: '/configs',
        name: 'Edit Config',
        component: configPage,
        meta: {
            title: '编辑标识',
            page: '编辑标识',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录',
            page: '登录页面',
            requiresAuth: false
        }
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: statistics,
        meta: {
            title: '邮件打开统计',
            page: '邮件打开统计',
            requiresAuth: true
        }
    },
    {
        path: '/shorturl',
        name: 'ShortUrl',
        component: shortUrlList,
        meta: {
            title: '点击记录',
            page: '邮件点击记录',
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = '邮件服务'
    }
    const token = localStorage['access_token']
    //根据路由标记，判断此请求是否需要登陆
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //需要登陆
        if (token) {
            //token存在，放行
            login.check(res => {
                if (!res.active) {
                    login.refresh(res => {
                        if (res.code !== 200) {
                            next({path: '/login', query: {redirect: to.fullPath}})
                        } else {
                            next()
                        }
                    })
                } else {
                    next()
                }
            });
        } else {
            //token不存在，跳转登陆页面
            next({path: '/login', query: {redirect: to.fullPath}})
        }
    } else {
        next()
    }
})

export default router
