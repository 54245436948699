<template>
  <v-app>
    <SnackBar/>
    <NavBar/>
    <GlobalLoading/>
    <AppBar/>
    <v-main>
      <v-container fluid>
        <transition name="slide-fade" mode="out-in">
          <router-view/>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar";
import NavBar from "@/components/NavBar";
import AppBar from "@/components/AppBar";
import GlobalLoading from "@/components/GlobalLoading";

export default {
  name: 'App',

  components: {
    SnackBar,
    NavBar,
    AppBar,
    GlobalLoading
  },

  data: () => ({
    botTokens: {},
    sidebar: [
      {
        id: 1,
        title: "机器人",
        icon: "mdi-pencil"
      },
      {
        id: 2,
        title: "智能回复",
        icon: "mdi-pencil"
      },
      {
        id: 3,
        title: "欢迎语",
        icon: "mdi-pencil"
      }
    ]
  }),
  created() {
  }
};
</script>
