<template>
    <v-container>
        <v-row class="justify-center">
            <v-col cols="12" lg="6">
                <v-row>
                    <v-col>
                        <v-select label="新用户" hint="(24h内)注册第一封邮件" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.new_user"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="新VIP" hint="(24h内)开通会员后" outlined persistent-hint :items="items"
                                  item-text="title"
                                  item-value="id" v-model="configs.new_vip"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="高评分异性" hint="推送近期热门、高评分异性" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.opposite"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="匹配用户推荐" hint="和你高匹配用户推荐" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.recommend"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="提醒" hint="会员到期提醒" outlined persistent-hint :items="items" item-text="title"
                                  item-value="id" v-model="configs.remind"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="登陆提醒" hint="登陆提醒(一周/半个月/一个月/三个月未登陆)" outlined persistent-hint
                                  :items="items" item-text="title" item-value="id" v-model="configs.login_remind"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="访问通知" hint="有用户查看过你的资料" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.guide_visitor"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="有私信" hint="已经2周没有登录，你有新的私信" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.guide_msg"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="关注者上线" hint="你关注的男神/女神上线啦！" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.attention"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="用户认证" hint="审核认证通过" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.approve"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="帖子内容审核" hint="帖子内容审核通过" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.dymanic"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select label="黑榜内容审核" hint="黑榜内容审核通过" outlined persistent-hint :items="items"
                                  item-text="title" item-value="id" v-model="configs.complaint"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import template from "@/utils/template";
    import config from "@/utils/config";

    export default {
        name: "config-page",
        data() {
            return {
                items: [],
                configs: {
                    new_user: undefined,
                    new_vip: undefined,
                    opposite: undefined,
                    recommend: undefined,
                    remind: undefined,
                    login_remind: undefined,
                    guide_visitor: undefined,
                    guide_msg: undefined,
                    attention: undefined,
                    approve: undefined,
                    dymanic: undefined,
                    complaint: undefined
                },
            }
        },
        methods: {
            getTemplateList() {
                template.getTemplateList(1, 10000, res => {
                    this.items = res.data.records
                })
            },
            getConfig() {
                config.getConfig(res => {
                    this.configs = {
                        new_user: parseInt(res.data.new_user),
                        new_vip: parseInt(res.data.new_vip),
                        opposite: parseInt(res.data.opposite),
                        recommend: parseInt(res.data.recommend),
                        remind: parseInt(res.data.remind),
                        login_remind: parseInt(res.data.login_remind),
                        guide_visitor: parseInt(res.data.guide_visitor),
                        guide_msg: parseInt(res.data.guide_msg),
                        attention: parseInt(res.data.attention),
                        approve: parseInt(res.data.approve),
                        dymanic: parseInt(res.data.dymanic),
                        complaint: parseInt(res.data.complaint)
                    }
                })
            },
        },
        created() {
            this.getTemplateList()
            this.getConfig()
        },
        watch: {
            configs: {
                handler(newConfig) {
                    config.updateConfig(newConfig, () => {
                    })
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
